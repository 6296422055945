import React from 'react';
import { Box, HStack, Text, Image, Center } from '@chakra-ui/react';
import { Icons } from './Icons';
import bio_image from './bio_image@3x.png';
import { bio } from '../../copy';

export const Bio = () => {
	return (
		<Center className='bio-center'>
			<HStack className='bio-hstack'>
				<Box className='bio-box'>
					<Box className='bio-copy'>
						<Text className='bio-headline'>{bio.headline}</Text>
						<Text className='bio-body' marginBottom='20px'>
							{bio.copy[0]}
						</Text>
						<Text className='bio-body' marginBottom='20px'>
							{bio.copy[1]}
						</Text>
						<Text className='bio-body' marginBottom='30px'>
							{bio.copy[2]}
						</Text>
					</Box>
					<Center>
						<Text className='bio-follow'>{bio.follow}</Text>
					</Center>
					<Center>
						<Icons />
					</Center>
				</Box>
				<Box className='bio-img-box'>
					<Center>
						<Image src={bio_image} className='bio-img' />
					</Center>
				</Box>
			</HStack>
		</Center>
	);
};
