import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Center,
	Box,
	HStack
} from '@chakra-ui/react';

import { LeftContent } from './LeftContent';
import { RightContent } from './RightContent';
import bgimg from './pinkleaf_small.jpg';

export const EmailModal = () => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			onOpen();
		}, 2000);
	}, []);

	const { isOpen, onClose, onOpen } = useDisclosure();

	const _handleSubscribe = () => {
		localStorage.setItem('subscribed', 'true');
		onClose();
	};

	const _handleClear = () => {
		localStorage.setItem('subscribed', 'false');
		onClose();
	};
	return (
		<div>
			<Modal onClose={onClose} isOpen={isOpen} isCentered size='5xl'>
				<ModalOverlay />
				<ModalContent className='modal-content' borderWidth='0px'>
					<ModalCloseButton color='white' bg='#e2796f' />
					<ModalBody style={{ backgroundImage: `url(${bgimg})` }}>
						<Center h='100%'>
							<Box bg='#e2796f' h='88%' w='96%'>
								<Center>
									<HStack>
										<Center>
											<Box className='modal-left-box'>
												<LeftContent />
											</Box>
											<Box className='modal-right-box'>
												<RightContent />
											</Box>
										</Center>
									</HStack>
								</Center>
							</Box>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</div>
	);
};
