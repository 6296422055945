import React from 'react';
import { Box, VStack, Text, Image, Center } from '@chakra-ui/react';
import { Icons } from './Icons';
import bio_image from './Mobile_Bio_Img@2x.png';
import { bio } from '../../copy';

export const Bio = () => {
	return (
		<Center className='mobile-about-bio-center'>
			<VStack>
				<Center className='mobile-bio-img'>
					<Image src={bio_image} />
				</Center>

				<Box textAlign='center'>
					<Text className='mobile-bio-headline'>{bio.headline}</Text>
					<Center>
						<Text className='mobile-bio-body' marginBottom='20px'>
							{bio.copy[0]}
						</Text>
					</Center>
					<Center>
						<Text className='mobile-bio-body' marginBottom='20px'>
							{bio.copy[1]}
						</Text>
					</Center>
					<Center>
						<Text className='mobile-bio-body' marginBottom='30px'>
							{bio.copy[2]}
						</Text>
					</Center>
				</Box>
				<Center>
					<Text className='mobile-bio-follow'>{bio.follow}</Text>
				</Center>
				<Center>
					<Icons />
				</Center>
			</VStack>
		</Center>
	);
};
